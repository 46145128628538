<template>
  <!-- Contact Section Start -->
  <section id="contact-us">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-12">
          <!-- Heading Text -->
          <div class="section-heading text-center">
            <h2>ติดต่อเรา</h2>
            <div class="line-title-center"></div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <form class="my-form" @submit.prevent="submit">
                <div style="color: red" v-if="$v.form.email.$error">
                  Email is required
                </div>
                <div class="form-group">
                  <label for="myemail"><i class="icon-envelope"></i></label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    v-model="form.email"
                  />
                </div>
                <div style="color: red" v-if="$v.form.name.$error">
                  Name is required
                </div>
                <div class="form-group">
                  <label for="fname"><i class="icon-user"></i></label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Name"
                    v-model="form.name"
                  />
                </div>
                <div style="color: red" v-if="$v.form.company.$error">
                  Company Name is required
                </div>
                <div class="form-group">
                  <label for="cname"><i class="icon-organization"></i></label>
                  <input
                    type="text"
                    class="form-control"
                    id="cname"
                    placeholder="Company Name"
                    v-model="form.company"
                  />
                </div>
                <div style="color: red" v-if="$v.form.mobile.$error">
                  Mobile is required
                </div>
                <div class="form-group">
                  <label for="mnumber"><i class="icon-phone"></i></label>
                  <input
                    type="number"
                    class="form-control"
                    id="mnumber"
                    placeholder="Mobile"
                    v-model="form.mobile"
                  />
                </div>
                <div style="color: red" id="errorRobot"></div>
                <div style="margin-bottom: 1rem;">
                  <vue-recaptcha
                    @verify="onVerify"
                    sitekey="6Lc2NrEaAAAAAMkjF3jggIIrh2aRL3jKmQr9mLCj"
                  ></vue-recaptcha>
                </div>
                <!-- <div>
                  <button
                    class="btn btn-submit btn-block"
                    type="submit"
                  >
                    Send
                  </button>
                </div> -->
                <vue-button-spinner
                  :is-loading="isLoading"
                  :disabled="isLoading"
                  type="submit"
                  class="btn btn-submit btn-block"
                  style="background-color: #43cea2;color: #fff;padding: 10px;height: auto;"
                >
                  <span>Send</span>
                </vue-button-spinner>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Section End -->
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import VueButtonSpinner from "vue-button-spinner";

export default {
  name: "contact",
  components: {
    VueRecaptcha,
    VueButtonSpinner,
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        company: "",
        name: "",
        mobile: "",
        robot: false,
      },
    };
  },
  validations: {
    form: {
      name: { required },
      company: { required },
      mobile: { required },
      email: { required, email },
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      if(this.form.robot == false) {
        return document.getElementById("errorRobot").innerHTML = "You are a robot?";
      }

      // to form submit after this
      this.isLoading = true;
      let self = this;
      self.$store.state.services.emailService
        .add(self.form)
        .then((r) => {
          this.isLoading = false;
          this.$swal({
            position: "center",
            icon: "success",
            title: "ขอบคุณค่ะ ที่สนใจสิ้นค้าและบริการของเรา",
            showConfirmButton: true,
            timer: 3000,
          });
        })
        .catch((r) => {
          console.error(r);
          this.isLoading = false;
          this.status = false;
        });
    },
    onVerify: function(response) {
      if (response) this.form.robot = true;
    },
  },
};
</script>
<style scoped></style>
